.pin-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;
}

.pin-input__circles {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pin-input__circle {
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  border: 2px solid #00aa46;
  border-radius: 60px;
  margin: 6px;
}

.pin-input__circle_filled {
  background-color: #00aa46;
}

.pin-input__circle_error {
  background-color: #ff2c2c;
  border-color: #ff2c2c;
}

.number-input {
  display: flex;
  flex-direction: column;
  margin: 24px;
  align-items: flex-end;
}

.number-input__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;
}

.number-input__button {
  border: 2px solid #00aa46;
  border-radius: 2px;
  background-color: #00aa46;
  color: white;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
  padding: 12px;
  margin: 6px;
}

.number-input__button-disabled {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.number-input__button:active {
  background-color: #00a444;
  border-color: #00a444;
}