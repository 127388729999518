.full-screen-confirm {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  
  .full-screen-confirm__title {
    font-size: 48px;
    margin: 0 48px 48px 48px;
    font-weight: bold;
    color: #323232;
    text-align: center;
  }
  .full-screen-confirm__buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }