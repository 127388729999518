.order-list {
  width: 350px;
  background-color: #FFFFFF;
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
}

.order-list::-webkit-scrollbar {
  display: none;
}

.orders-container {
  display: flex;
  flex-direction: column;
}

.order-list-heading {
  height: 40px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 6px 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.order-list-heading .text {
  flex: 1 0 auto;
}

.order-list-heading .count {
  font-size: 24px;
}

.order-list__order {
  min-height: 96px;
  border: 1px solid;
  border-radius: 2px;
  margin: 3px 12px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.market-orders__container .order-list__order, .purchased-buffets__container .order-list__order {
  border: 1px solid;
  border-top: 0;
  width: 744px;
  margin: 0 12px;
}
.purchased-buffets__container .order-list__order {
  border-color: #d9d9d9;
}
.purchased-buffets__container div:first-of-type .order-list__order {
  border-top: 1px solid #d9d9d9;
}
.market-orders__container div:first-of-type .order-list__order {
  border-top: 1px solid;
}
.order-list-customer {
  width: 40%;
}
.order-list-customer__name {
  font-size: 20px;
  margin-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.order-list-order__column {
  min-width: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-list-order__column_tall {
  justify-content: flex-start;
  margin-top: 3px;
  margin-left: 3px;
}

.order-list-order__column_item-count {
  margin: 0px 24px;
}

.order-list-order__item-count {
  font-size: 36px;
  font-weight: bold;
}

.order-list-order__column_table-number {
  width: 54px;
  border-radius: 2px;
  font-weight: bold;
  height: 36px;
  margin: 0px;
}

.order-list-order__column_table-number-not-given {
  background-color: lightgrey;
  color: #323232;
}

.order-list-order__column_table-number-given {
  background-color: #FFAA00;
  color: white;
}

.order-list-order__column_takeaway {
  background-color: #FFAA00;
  border-radius: 2px;
  min-height: 36px;
}

.order-list-order__column_middle .order-list-order__column:nth-child(2n) {
  margin: 0px 2px;
}

.order-list-order__column_pizza, .order-list-order__column_hamburger {
  background-color: #323232;
  border-radius: 2px;
  height: 36px;
  margin: 0px;
}

.order-list-order__column_message {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border: 2px solid #ffaa00;
  border-radius: 2px;
  height: 32px;
  margin: 0px;
  padding: 0px 7px;
}

.order-list-order__column_message-text {
  margin-left: 10px;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
}

.order-list-order__column_stack_middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0px;
}

.order-list-order__column_stack_start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0px;
}

.order-list-order__column_stack_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0px;
}

.order-item__production-finished-counter {
  background-color: #323232;
  color: #FFFFFF;
  padding: 3px 6px;
  border-radius: 2px;
  min-width: 60px;
  text-align: center;
}

.order-item__production-finished-counter_urgency-3 {
  background-color: #FF2C2C;
}

.order-list-order__column_order-id {
  font-size: 18px;
  width: 86px;
  min-height: 36px;
}

.order-list-order__column_order-content {
  font-size: 18px;
  width: 400px;
  min-height: 36px;
  align-items: flex-start;
}

.order-list-order__delivery-time {
  width: 65px;
  margin: 0px 12px;
}

.order-delivery-time__minutes {
  font-weight: bold;
  font-size: 24px;
}

.order-list-order__delivery-time_time {
  font-weight: bold;
  font-size: 24px;
}

.order-list-order__column_middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0px;
}

.order-list__order_selected {
  border-style: solid;
  border-width: 3px;
  margin: 1px 10px;
  /* TODO arrow head */
}

.order-list__order_urgency-1 {
  background-color: #F7FCF9;
  border-color: #00AA46;
  color: #00AA46;
}

.order-list__order_urgency-2 {
  background-color: #FFF9F2;
  border-color: #FFAA00;
  color: #FFAA00;
}

.order-list__order_urgency-3 {
  background-color: #FFF4F4;
  border-color: #FF2C2C;
  color: #FF2C2C;
}
.reset-width {
  width: unset;
}