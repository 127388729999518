.main-header {
  display: flex;
  flex-direction: row;
  min-height: 96px;
  height: 96px;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #d9d9d9;
}

.main-header-orders {
  background-color: #323232;
  color: white;
  justify-content: space-between;
}

.main-header-OPTIONS_VIEWS {
  justify-content: flex-start;
}

.main-header-OPTIONS_QUEUE, 
.main-header-ORDER_SETTINGS {
  justify-content: flex-end;
}

.main-header__view {
  display: flex;
  flex-basis: 0;
  flex-grow: 2;
}

.main-header__time {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  flex-basis: 0;
  flex-grow: 1;
}

.main-header__right {
  display: flex;
  flex-basis: 0;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 2;
}

.button-queue {
  border: 2px solid #FFFFFF;
}

.button-placeholder {
  border-color: rgba(0,0,0,0);
  width: auto;
}

.version {
  color: #d9d9d9;
  position: absolute;
  bottom: 12px;
  right: 96px;
}