input[type='checkbox'] {
  display: none;
}

.checkbox-container {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.checkbox {
  border-radius: 2px;
  width: 24px;
  height: 24px;
  border: 1px solid #d9d9d9;
  display: flex;
}

.checkbox_checked {
  background-color: #00ae00;
  border-color: #00ae00;
}

.checkbox__checkmark {
  margin-top: 5px;
  margin-left: 4px;
}