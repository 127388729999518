.full-screen-error {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.full-screen-error__description {
  font-size: 36px;
  font-weight: bold;
  margin: 24px 0px;
}

.full-screen-error__title {
  font-size: 288px;
  margin: 24px 0px 29px 0px;
  font-weight: bold;
  color: #ff2c2c;
}