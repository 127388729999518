.locked-view {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.locked-view__title {
  font-weight: bold;
  font-size: 48px;
  margin: 12px;
}