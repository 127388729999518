.change-pin {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.change-pin__title {
  font-weight: bold;
  font-size: 48px;
  margin: 12px;
}

.change-pin__finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;
}

.change-pin__ok-button {
  height: 48px;
  width: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 12px 6px;
  border-radius: 2px;
  border: 1px solid #00AA46;
  background-color: #00AA46;
  color: white;
  font-weight: bold;
  font-size: 24px;
}