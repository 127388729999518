.order-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

.order-details_urgency-1 {
  background-color: #F7FCF9;
}

.order-details_urgency-2 {
  background-color: #FFF9F2;
}

.order-details_urgency-3 {
  background-color: #FFF4F4;
}

.order-details__header {
  margin: 12px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 60px;
}

.order-details__order-items {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
}

.order-details__actions {
  display: flex;
  flex-direction: row;
}
.order-details__action {
  color: #FFFFFF;
  background-color: #00AA46;
  font-size: 24px;
  font-weight: bold;
  min-height: 48px;
  height: 48px;
  margin: 12px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 2px;
}

.order-details__action_E_DISPOSED {
  background-color: #FF2C2C;
}

.order-details__action_disabled {
  background-color: lightgray;
}

.order-details-action__left {
  min-width: 25%;
}

.order-details-action__middle {
  width: 50%;
  text-align: center;
}

.order-details-action__right {
  width: 25%;
  text-align: right;
}


.order-details-header__left {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.order-details-header__item-count {
  font-size: 36px;
  font-weight: bold;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.order-details-order-item__item-count {
  flex: 0 1 auto;
  margin-right: 6px;
  font-size: 36px;
  font-weight: bold;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-details-header__item-count_urgency-1 {
  color: #00AA46;
}

.order-details-header__item-count_urgency-2 {
  color: #FFAA00;
}

.order-details-header__item-count_urgency-3 {
  color: #FF2C2C;
}

.order-details-header__takeaway {
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #FFAA00;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  border-radius: 2px;
  padding: 0px 24px;
}

.order-details-header__right {
  display: flex;
}

.order-details-header__time {
  font-size: 36px;
  font-weight: bold;
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.order-details-header__time_urgency-1 {
  color: #00AA46;
}

.order-details-header__time_urgency-2 {
  color: #FFAA00;
}

.order-details-header__time_urgency-3 {
  color: #FF2C2C;
}

.order-details-order-item {
  margin: 6px 12px;
  padding: 12px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  border-radius: 2px;
}

.order-details-order-item__middle {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.order-details-order-item__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-details-order-item__item_pizza,
.order-details-order-item__item_hamburger {
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
  background-color: #323232;
  border-radius: 2px;
}

.order-details-order-item__price {
  font-weight: bold;
  font-size: 18px;
  margin-left: 12px;
  min-width: 90px;
  text-align: end;
}

.order-details-order-item__name {
  font-size: 24px;
  font-weight: bold;
}

.order-details-order-message__message {
  font-size: 18px;
  font-weight: bold;
}

.order-details-order-message {
  margin: 6px 12px;
  padding: 12px;
  border: 2px solid #ffaa00;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  border-radius: 2px;
}

.order-details-order-message__icon {
  flex: 0 1 auto;
  margin-right: 6px;
  font-size: 36px;
  font-weight: bold;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-details-order-message__middle {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.order-details-order-customizations {
  margin: 13px;
}

.order-details-order-customizations__customizations {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.order-details-order-customizations__customizations-customization {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 60px;
  background-color: #ffffff;
  flex: 1 0 21%;
  margin: 5px;
}

.order-details-order-customizations__customizations-customization-text {
  font-size: 18px;
  font-weight: bold;
}
.customizations-customization-text--centered {
  text-align: center;
}
.order-details-order-item__additional-products-and-customization {
 color: #FFAA00;
 font-size: 18px;
 font-weight: bold;
 margin-top: 2px;
}

.order-details-order-item__removed-additional-products-and-customization {
  color: #ff2c2c;
  font-size: 18px;
  font-weight: bold;
  margin-top: 2px;
 }
