.superheader {
    display: flex;
    flex-direction: row;
    top: 0;
    width: 100%;
    height: 36px;
    justify-content: center;
    align-items: center;
}
.superheader__text {
    font-weight: bold;
    font-size: 15px;
}