.portions-blacklist-main-header {
    display: block;
    height: auto;
}
.blacklist-category-tab-container__tab {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: normal;
}
.blacklist-category-tab-container__tab:first-child {
    border-right: 1px solid #00aa46;
}
.blacklist-category-tab-container__tab--selected {
    background-color: #f2fbf2;
    font-weight: bold;
}
.category-header {
    font-size: 24px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 12px;
}
.portions-blacklist-menu-container {
    display: flex;
    flex-direction: column;
    margin: 24px 108px 80px 108px;
}
.blacklist-category-item {
    display: flex;
    align-items: center;
    height: 72px;
    border: 1px solid #d9d9d9;
    border-top: 0;
}
.blacklist-category-item--available {
    background-color: #f2fbf2;
}
.blacklist-category-item:first-child {
    border-top: 1px solid #d9d9d9;
}
.blacklist-category-item__checkbox-container {
    margin: 24px;
}
.blacklist-category-item__availability {
    margin-left: auto; 
    margin-right: 24px;
    font-size: 15px;
    font-weight: normal;
    color: #323232;
}
.blacklist-category-item__availability--blacklisted {
    font-weight: bold;
    color: #ffaa00;
}
.blacklist-category-item__portion-name {
    color: #323232;
    font-weight: bold;
}
.portions-blacklist-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.portions-blacklist-header__text {
    flex: 1;
    text-align: center;
    font-size: 24px;
    margin-top: 21px;
    margin-bottom: 23px;
}
.portions-blacklist-header__button {
    display: flex;
    align-items: center;
    margin: 0;
}
.blacklist-category-tab-container {
    display: flex;
    height: 60px;
    border: 1px solid #00aa46;
    align-items: center,
}
.portions-blacklist-header-wrapper {
    margin: 12px;
}