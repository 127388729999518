/* stylelint-disable */
@-webkit-keyframes ld-cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5)
  }
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes ld-cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5)
  }
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ld-cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5)
  }
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes ld-cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5)
  }
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes ld-cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5)
  }
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.ld.ld-cycle {
  -webkit-animation: ld-cycle 1s infinite linear;
  animation: ld-cycle 1s infinite linear
}

@-webkit-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.ld.ld-spin {
  -webkit-animation: ld-spin 1s infinite;
  animation: ld-spin 1s infinite
}

@-webkit-keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg)
  }
}

@-moz-keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg)
  }
}

@-webkit-keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg)
  }
}

@-o-keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg)
  }
}

@keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg)
  }
}

.ld.ld-spin-fast {
  -webkit-animation: ld-spin-fast 1s infinite;
  animation: ld-spin-fast 1s infinite
}

@-webkit-keyframes ld-heartbeat {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25)
  }
  39% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
  }
  45% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }
  600% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-moz-keyframes ld-heartbeat {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25)
  }
  39% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
  }
  45% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }
  600% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes ld-heartbeat {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25)
  }
  39% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
  }
  45% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }
  600% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-o-keyframes ld-heartbeat {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25)
  }
  39% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
  }
  45% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }
  600% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes ld-heartbeat {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25)
  }
  39% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
  }
  45% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }
  600% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.ld.ld-heartbeat {
  -webkit-animation: ld-heartbeat .8s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ld-heartbeat .8s infinite cubic-bezier(0.215, 0.61, 0.355, 1)
}

@-webkit-keyframes ld-squeeze {
  0% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
  50% {
    -webkit-transform: scale(0.5, 1);
    transform: scale(0.5, 1)
  }
  100% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
}

@-moz-keyframes ld-squeeze {
  0% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
  50% {
    -webkit-transform: scale(0.5, 1);
    transform: scale(0.5, 1)
  }
  100% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
}

@-webkit-keyframes ld-squeeze {
  0% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
  50% {
    -webkit-transform: scale(0.5, 1);
    transform: scale(0.5, 1)
  }
  100% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
}

@-o-keyframes ld-squeeze {
  0% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
  50% {
    -webkit-transform: scale(0.5, 1);
    transform: scale(0.5, 1)
  }
  100% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
}

@keyframes ld-squeeze {
  0% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
  50% {
    -webkit-transform: scale(0.5, 1);
    transform: scale(0.5, 1)
  }
  100% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5)
  }
}

.ld.ld-squeeze {
  -webkit-animation: ld-squeeze .8s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ld-squeeze .8s infinite cubic-bezier(0.215, 0.61, 0.355, 1)
}

@-webkit-keyframes ld-blah {
  0% {
    width: 52px;
    height: 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  49.99999% {
    width: 52px 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  50% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

@-moz-keyframes ld-blah {
  0% {
    width: 52px;
    height: 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  49.99999% {
    width: 52px 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  50% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

@-webkit-keyframes ld-blah {
  0% {
    width: 52px;
    height: 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  49.99999% {
    width: 52px 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  50% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

@-o-keyframes ld-blah {
  0% {
    width: 52px;
    height: 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  49.99999% {
    width: 52px 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  50% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

@keyframes ld-blah {
  0% {
    width: 52px;
    height: 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  49.99999% {
    width: 52px 52px;
    margin: -1px 0 0 -1px;
    border-width: 7px;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  50% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    width: 50px;
    height: 50px;
    margin: 0;
    border-width: 5px;
    border-color: transparent transparent #f90 #f90;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

.ld.ld-blah {
  -webkit-animation: ld-blah 1s infinite linear;
  animation: ld-blah 1s infinite linear
}

.ld.ld-rp {
  -webkit-transform: scale(3);
  transform: scale(3);
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  margin: 30px 0 30px 160px;
  overflow: hidden;
  position: relative
}

.ld.ld-rp:before {
  top: 0;
  left: 0;
  content: " ";
  display: block;
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: transparent transparent #f90 #f90
}

.ld.ld-rp:after {
  top: 0;
  left: 0;
  content: " ";
  display: block;
  position: absolute;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  border: 7px solid;
  border-color: transparent transparent #fff #fff;
  margin: -1px 0 0 -1px;
  -webkit-animation: ld-blah 1s infinite ease;
  animation: ld-blah 1s infinite ease
}

@-webkit-keyframes ld-rubber {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
  20% {
    -webkit-transform: scale(1.5, 1);
    transform: scale(1.5, 1)
  }
  30% {
    -webkit-transform: scale(0.8, 1);
    transform: scale(0.8, 1)
  }
  40% {
    -webkit-transform: scale(1.3, 1);
    transform: scale(1.3, 1)
  }
  50% {
    -webkit-transform: scale(0.85, 1);
    transform: scale(0.85, 1)
  }
  60% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1)
  }
  70% {
    -webkit-transform: scale(0.9, 1);
    transform: scale(0.9, 1)
  }
  80% {
    -webkit-transform: scale(1.1, 1);
    transform: scale(1.1, 1)
  }
  90% {
    -webkit-transform: scale(0.95, 1);
    transform: scale(0.95, 1)
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
}

@-moz-keyframes ld-rubber {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
  20% {
    -webkit-transform: scale(1.5, 1);
    transform: scale(1.5, 1)
  }
  30% {
    -webkit-transform: scale(0.8, 1);
    transform: scale(0.8, 1)
  }
  40% {
    -webkit-transform: scale(1.3, 1);
    transform: scale(1.3, 1)
  }
  50% {
    -webkit-transform: scale(0.85, 1);
    transform: scale(0.85, 1)
  }
  60% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1)
  }
  70% {
    -webkit-transform: scale(0.9, 1);
    transform: scale(0.9, 1)
  }
  80% {
    -webkit-transform: scale(1.1, 1);
    transform: scale(1.1, 1)
  }
  90% {
    -webkit-transform: scale(0.95, 1);
    transform: scale(0.95, 1)
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
}

@-webkit-keyframes ld-rubber {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
  20% {
    -webkit-transform: scale(1.5, 1);
    transform: scale(1.5, 1)
  }
  30% {
    -webkit-transform: scale(0.8, 1);
    transform: scale(0.8, 1)
  }
  40% {
    -webkit-transform: scale(1.3, 1);
    transform: scale(1.3, 1)
  }
  50% {
    -webkit-transform: scale(0.85, 1);
    transform: scale(0.85, 1)
  }
  60% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1)
  }
  70% {
    -webkit-transform: scale(0.9, 1);
    transform: scale(0.9, 1)
  }
  80% {
    -webkit-transform: scale(1.1, 1);
    transform: scale(1.1, 1)
  }
  90% {
    -webkit-transform: scale(0.95, 1);
    transform: scale(0.95, 1)
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
}

@-o-keyframes ld-rubber {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
  20% {
    -webkit-transform: scale(1.5, 1);
    transform: scale(1.5, 1)
  }
  30% {
    -webkit-transform: scale(0.8, 1);
    transform: scale(0.8, 1)
  }
  40% {
    -webkit-transform: scale(1.3, 1);
    transform: scale(1.3, 1)
  }
  50% {
    -webkit-transform: scale(0.85, 1);
    transform: scale(0.85, 1)
  }
  60% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1)
  }
  70% {
    -webkit-transform: scale(0.9, 1);
    transform: scale(0.9, 1)
  }
  80% {
    -webkit-transform: scale(1.1, 1);
    transform: scale(1.1, 1)
  }
  90% {
    -webkit-transform: scale(0.95, 1);
    transform: scale(0.95, 1)
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
}

@keyframes ld-rubber {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
  20% {
    -webkit-transform: scale(1.5, 1);
    transform: scale(1.5, 1)
  }
  30% {
    -webkit-transform: scale(0.8, 1);
    transform: scale(0.8, 1)
  }
  40% {
    -webkit-transform: scale(1.3, 1);
    transform: scale(1.3, 1)
  }
  50% {
    -webkit-transform: scale(0.85, 1);
    transform: scale(0.85, 1)
  }
  60% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1)
  }
  70% {
    -webkit-transform: scale(0.9, 1);
    transform: scale(0.9, 1)
  }
  80% {
    -webkit-transform: scale(1.1, 1);
    transform: scale(1.1, 1)
  }
  90% {
    -webkit-transform: scale(0.95, 1);
    transform: scale(0.95, 1)
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
}

.ld.ld-rubber {
  -webkit-animation: ld-rubber 1s ease-out infinite;
  animation: ld-rubber 1s ease-out infinite
}

@-webkit-keyframes ld-tick {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  20% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg)
  }
  30% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  40% {
    -webkit-transform: rotate(-21deg);
    transform: rotate(-21deg)
  }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg)
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg)
  }
  90% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@-moz-keyframes ld-tick {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  20% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg)
  }
  30% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  40% {
    -webkit-transform: rotate(-21deg);
    transform: rotate(-21deg)
  }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg)
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg)
  }
  90% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@-webkit-keyframes ld-tick {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  20% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg)
  }
  30% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  40% {
    -webkit-transform: rotate(-21deg);
    transform: rotate(-21deg)
  }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg)
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg)
  }
  90% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@-o-keyframes ld-tick {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  20% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg)
  }
  30% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  40% {
    -webkit-transform: rotate(-21deg);
    transform: rotate(-21deg)
  }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg)
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg)
  }
  90% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@keyframes ld-tick {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  20% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg)
  }
  30% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  40% {
    -webkit-transform: rotate(-21deg);
    transform: rotate(-21deg)
  }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg)
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg)
  }
  90% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

.ld.ld-tick {
  -webkit-animation: ld-tick 1s ease-out infinite;
  animation: ld-tick 1s ease-out infinite
}

@-webkit-keyframes ld-radio {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.13, 1)
  }
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0
  }
  5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0
  }
}

@-moz-keyframes ld-radio {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.13, 1)
  }
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0
  }
  5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0
  }
}

@-webkit-keyframes ld-radio {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.13, 1)
  }
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0
  }
  5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0
  }
}

@-o-keyframes ld-radio {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.13, 1)
  }
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0
  }
  5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0
  }
}

@keyframes ld-radio {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.13, 1)
  }
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0
  }
  5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0
  }
}

.ld.ld-radio {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0
}

.ld.ld-radio:after {
  -webkit-animation: ld-radio 1s infinite;
  animation: ld-radio 1s infinite;
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 6px solid #000;
  border-radius: 50%;
  background: 0
}

.ld.ld-radio.square:after {
  border-radius: 0
}

@-webkit-keyframes ld-clock {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  8.333% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  16.667% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg)
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  33.333% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg)
  }
  41.667% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  58.333% {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg)
  }
  66.667% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg)
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }
  83.333% {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg)
  }
  91.667% {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes ld-clock {
  0 {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  8.333% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  16.667% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg)
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  33.333% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg)
  }
  41.667% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  58.333% {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg)
  }
  66.667% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg)
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }
  83.333% {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg)
  }
  91.667% {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ld-clock {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  8.333% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  16.667% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg)
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  33.333% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg)
  }
  41.667% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  58.333% {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg)
  }
  66.667% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg)
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }
  83.333% {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg)
  }
  91.667% {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes ld-clock {
  0 {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  8.333% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  16.667% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg)
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  33.333% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg)
  }
  41.667% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  58.333% {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg)
  }
  66.667% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg)
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }
  83.333% {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg)
  }
  91.667% {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes ld-clock {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  8.333% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }
  16.667% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg)
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  33.333% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg)
  }
  41.667% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg)
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  58.333% {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg)
  }
  66.667% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg)
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }
  83.333% {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg)
  }
  91.667% {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.ld.ld-clock {
  -webkit-animation: ld-clock 9s infinite cubic-bezier(0, 0.7, 0.3, 1);
  animation: ld-clock 9s infinite cubic-bezier(0, 0.7, 0.3, 1)
}

@-webkit-keyframes ld-skew {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  25%, 75% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  0% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
  25% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  50% {
    -webkit-transform: skewX(-20deg) scale(1);
    transform: skewX(-20deg) scale(1)
  }
  75% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  100% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
}

@-moz-keyframes ld-skew {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  25%, 75% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  0% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
  25% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  50% {
    -webkit-transform: skewX(-20deg) scale(1);
    transform: skewX(-20deg) scale(1)
  }
  75% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  100% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
}

@-webkit-keyframes ld-skew {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  25%, 75% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  0% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
  25% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  50% {
    -webkit-transform: skewX(-20deg) scale(1);
    transform: skewX(-20deg) scale(1)
  }
  75% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  100% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
}

@-o-keyframes ld-skew {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  25%, 75% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  0% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
  25% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  50% {
    -webkit-transform: skewX(-20deg) scale(1);
    transform: skewX(-20deg) scale(1)
  }
  75% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  100% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
}

@keyframes ld-skew {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  25%, 75% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  0% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
  25% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  50% {
    -webkit-transform: skewX(-20deg) scale(1);
    transform: skewX(-20deg) scale(1)
  }
  75% {
    -webkit-transform: skewX(0) scale(0.9);
    transform: skewX(0) scale(0.9)
  }
  100% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1)
  }
}

.ld.ld-skew {
  -webkit-animation: ld-skew 1s infinite;
  animation: ld-skew 1s infinite
}

@-webkit-keyframes ld-tremble {
  0% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  5% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  10% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  15% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  20% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  25% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  30% {
    -webkit-transform: translate(1%, 3%);
    transform: translate(1%, 3%)
  }
  35% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  40% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  45% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  50% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  55% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  60% {
    -webkit-transform: translate(3%, 1%);
    transform: translate(3%, 1%)
  }
  65% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  70% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  75% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  80% {
    -webkit-transform: translate(2%, 3%);
    transform: translate(2%, 3%)
  }
  85% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  90% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  95% {
    -webkit-transform: translate(3%, 2%);
    transform: translate(3%, 2%)
  }
}

@-moz-keyframes ld-tremble {
  0 {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  5% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  10% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  15% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  20% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  25% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  30% {
    -webkit-transform: translate(1%, 3%);
    transform: translate(1%, 3%)
  }
  35% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  40% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  45% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  50% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  55% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  60% {
    -webkit-transform: translate(3%, 1%);
    transform: translate(3%, 1%)
  }
  65% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  70% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  75% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  80% {
    -webkit-transform: translate(2%, 3%);
    transform: translate(2%, 3%)
  }
  85% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  90% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  95% {
    -webkit-transform: translate(3%, 2%);
    transform: translate(3%, 2%)
  }
}

@-webkit-keyframes ld-tremble {
  0% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  5% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  10% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  15% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  20% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  25% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  30% {
    -webkit-transform: translate(1%, 3%);
    transform: translate(1%, 3%)
  }
  35% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  40% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  45% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  50% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  55% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  60% {
    -webkit-transform: translate(3%, 1%);
    transform: translate(3%, 1%)
  }
  65% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  70% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  75% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  80% {
    -webkit-transform: translate(2%, 3%);
    transform: translate(2%, 3%)
  }
  85% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  90% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  95% {
    -webkit-transform: translate(3%, 2%);
    transform: translate(3%, 2%)
  }
}

@-o-keyframes ld-tremble {
  0 {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  5% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  10% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  15% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  20% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  25% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  30% {
    -webkit-transform: translate(1%, 3%);
    transform: translate(1%, 3%)
  }
  35% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  40% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  45% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  50% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  55% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  60% {
    -webkit-transform: translate(3%, 1%);
    transform: translate(3%, 1%)
  }
  65% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  70% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  75% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  80% {
    -webkit-transform: translate(2%, 3%);
    transform: translate(2%, 3%)
  }
  85% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  90% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  95% {
    -webkit-transform: translate(3%, 2%);
    transform: translate(3%, 2%)
  }
}

@keyframes ld-tremble {
  0% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  5% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  10% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  15% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  20% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  25% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  30% {
    -webkit-transform: translate(1%, 3%);
    transform: translate(1%, 3%)
  }
  35% {
    -webkit-transform: translate(0, 1%);
    transform: translate(0, 1%)
  }
  40% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%)
  }
  45% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  50% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%)
  }
  55% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%)
  }
  60% {
    -webkit-transform: translate(3%, 1%);
    transform: translate(3%, 1%)
  }
  65% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  70% {
    -webkit-transform: translate(3%, 0);
    transform: translate(3%, 0)
  }
  75% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  80% {
    -webkit-transform: translate(2%, 3%);
    transform: translate(2%, 3%)
  }
  85% {
    -webkit-transform: translate(1%, 0);
    transform: translate(1%, 0)
  }
  90% {
    -webkit-transform: translate(0, 2%);
    transform: translate(0, 2%)
  }
  95% {
    -webkit-transform: translate(3%, 2%);
    transform: translate(3%, 2%)
  }
}

.ld.ld-tremble {
  -webkit-animation: ld-tremble 1s infinite;
  animation: ld-tremble 1s infinite
}

@-webkit-keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0)
  }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  100% {
    -webkit-transform: scale(1) translate(0, 200%) skewX(20deg);
    transform: scale(1) translate(0, 200%) skewX(20deg)
  }
}

@-moz-keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0)
  }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  100% {
    -webkit-transform: scale(1) translate(0, 200%) skewX(20deg);
    transform: scale(1) translate(0, 200%) skewX(20deg)
  }
}

@-webkit-keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0)
  }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  100% {
    -webkit-transform: scale(1) translate(0, 200%) skewX(20deg);
    transform: scale(1) translate(0, 200%) skewX(20deg)
  }
}

@-o-keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0)
  }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  100% {
    -webkit-transform: scale(1) translate(0, 200%) skewX(20deg);
    transform: scale(1) translate(0, 200%) skewX(20deg)
  }
}

@keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0)
  }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg)
  }
  100% {
    -webkit-transform: scale(1) translate(0, 200%) skewX(20deg);
    transform: scale(1) translate(0, 200%) skewX(20deg)
  }
}

.ld.ld-hit {
  -webkit-animation: ld-hit 2s infinite;
  animation: ld-hit 2s infinite
}

@-webkit-keyframes ld-wander-h {
  0% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  50% {
    -webkit-transform: translate(35%, 0);
    transform: translate(35%, 0)
  }
  100% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
}

@-moz-keyframes ld-wander-h {
  0% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  50% {
    -webkit-transform: translate(35%, 0);
    transform: translate(35%, 0)
  }
  100% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
}

@-webkit-keyframes ld-wander-h {
  0% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  50% {
    -webkit-transform: translate(35%, 0);
    transform: translate(35%, 0)
  }
  100% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
}

@-o-keyframes ld-wander-h {
  0% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  50% {
    -webkit-transform: translate(35%, 0);
    transform: translate(35%, 0)
  }
  100% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
}

@keyframes ld-wander-h {
  0% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  50% {
    -webkit-transform: translate(35%, 0);
    transform: translate(35%, 0)
  }
  100% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
}

.ld.ld-wander-h {
  -webkit-animation: ld-wander-h 1s infinite ease-out;
  animation: ld-wander-h 1s infinite ease-out
}

@-webkit-keyframes ld-wander-v {
  0% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
  50% {
    -webkit-transform: translate(0, 35%);
    transform: translate(0, 35%)
  }
  100% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
}

@-moz-keyframes ld-wander-v {
  0% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
  50% {
    -webkit-transform: translate(0, 35%);
    transform: translate(0, 35%)
  }
  100% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
}

@-webkit-keyframes ld-wander-v {
  0% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
  50% {
    -webkit-transform: translate(0, 35%);
    transform: translate(0, 35%)
  }
  100% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
}

@-o-keyframes ld-wander-v {
  0% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
  50% {
    -webkit-transform: translate(0, 35%);
    transform: translate(0, 35%)
  }
  100% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
}

@keyframes ld-wander-v {
  0% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
  50% {
    -webkit-transform: translate(0, 35%);
    transform: translate(0, 35%)
  }
  100% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%)
  }
}

.ld.ld-wander-v {
  -webkit-animation: ld-wander-v 1s infinite ease-out;
  animation: ld-wander-v 1s infinite ease-out
}

@-webkit-keyframes ld-orbit {
  0% {
    -webkit-transform: translate(30%, 0) rotate(0);
    transform: translate(30%, 0) rotate(0)
  }
  12.5% {
    -webkit-transform: translate(21%, 21%) rotate(45deg);
    transform: translate(21%, 21%) rotate(45deg)
  }
  25% {
    -webkit-transform: translate(0, 30%) rotate(90deg);
    transform: translate(0, 30%) rotate(90deg)
  }
  37.5% {
    -webkit-transform: translate(-21%, 21%) rotate(135deg);
    transform: translate(-21%, 21%) rotate(135deg)
  }
  50% {
    -webkit-transform: translate(-30%, 0) rotate(180deg);
    transform: translate(-30%, 0) rotate(180deg)
  }
  62.5% {
    -webkit-transform: translate(-21%, -21%) rotate(225deg);
    transform: translate(-21%, -21%) rotate(225deg)
  }
  75% {
    -webkit-transform: translate(0, -30%) rotate(270deg);
    transform: translate(0, -30%) rotate(270deg)
  }
  87.5% {
    -webkit-transform: translate(21%, -21%) rotate(315deg);
    transform: translate(21%, -21%) rotate(315deg)
  }
  100% {
    -webkit-transform: translate(30%, 0) rotate(360deg);
    transform: translate(30%, 0) rotate(360deg)
  }
}

@-moz-keyframes ld-orbit {
  0% {
    -webkit-transform: translate(30%, 0) rotate(0);
    transform: translate(30%, 0) rotate(0)
  }
  12.5% {
    -webkit-transform: translate(21%, 21%) rotate(45deg);
    transform: translate(21%, 21%) rotate(45deg)
  }
  25% {
    -webkit-transform: translate(0, 30%) rotate(90deg);
    transform: translate(0, 30%) rotate(90deg)
  }
  37.5% {
    -webkit-transform: translate(-21%, 21%) rotate(135deg);
    transform: translate(-21%, 21%) rotate(135deg)
  }
  50% {
    -webkit-transform: translate(-30%, 0) rotate(180deg);
    transform: translate(-30%, 0) rotate(180deg)
  }
  62.5% {
    -webkit-transform: translate(-21%, -21%) rotate(225deg);
    transform: translate(-21%, -21%) rotate(225deg)
  }
  75% {
    -webkit-transform: translate(0, -30%) rotate(270deg);
    transform: translate(0, -30%) rotate(270deg)
  }
  87.5% {
    -webkit-transform: translate(21%, -21%) rotate(315deg);
    transform: translate(21%, -21%) rotate(315deg)
  }
  100% {
    -webkit-transform: translate(30%, 0) rotate(360deg);
    transform: translate(30%, 0) rotate(360deg)
  }
}

@-webkit-keyframes ld-orbit {
  0% {
    -webkit-transform: translate(30%, 0) rotate(0);
    transform: translate(30%, 0) rotate(0)
  }
  12.5% {
    -webkit-transform: translate(21%, 21%) rotate(45deg);
    transform: translate(21%, 21%) rotate(45deg)
  }
  25% {
    -webkit-transform: translate(0, 30%) rotate(90deg);
    transform: translate(0, 30%) rotate(90deg)
  }
  37.5% {
    -webkit-transform: translate(-21%, 21%) rotate(135deg);
    transform: translate(-21%, 21%) rotate(135deg)
  }
  50% {
    -webkit-transform: translate(-30%, 0) rotate(180deg);
    transform: translate(-30%, 0) rotate(180deg)
  }
  62.5% {
    -webkit-transform: translate(-21%, -21%) rotate(225deg);
    transform: translate(-21%, -21%) rotate(225deg)
  }
  75% {
    -webkit-transform: translate(0, -30%) rotate(270deg);
    transform: translate(0, -30%) rotate(270deg)
  }
  87.5% {
    -webkit-transform: translate(21%, -21%) rotate(315deg);
    transform: translate(21%, -21%) rotate(315deg)
  }
  100% {
    -webkit-transform: translate(30%, 0) rotate(360deg);
    transform: translate(30%, 0) rotate(360deg)
  }
}

@-o-keyframes ld-orbit {
  0% {
    -webkit-transform: translate(30%, 0) rotate(0);
    transform: translate(30%, 0) rotate(0)
  }
  12.5% {
    -webkit-transform: translate(21%, 21%) rotate(45deg);
    transform: translate(21%, 21%) rotate(45deg)
  }
  25% {
    -webkit-transform: translate(0, 30%) rotate(90deg);
    transform: translate(0, 30%) rotate(90deg)
  }
  37.5% {
    -webkit-transform: translate(-21%, 21%) rotate(135deg);
    transform: translate(-21%, 21%) rotate(135deg)
  }
  50% {
    -webkit-transform: translate(-30%, 0) rotate(180deg);
    transform: translate(-30%, 0) rotate(180deg)
  }
  62.5% {
    -webkit-transform: translate(-21%, -21%) rotate(225deg);
    transform: translate(-21%, -21%) rotate(225deg)
  }
  75% {
    -webkit-transform: translate(0, -30%) rotate(270deg);
    transform: translate(0, -30%) rotate(270deg)
  }
  87.5% {
    -webkit-transform: translate(21%, -21%) rotate(315deg);
    transform: translate(21%, -21%) rotate(315deg)
  }
  100% {
    -webkit-transform: translate(30%, 0) rotate(360deg);
    transform: translate(30%, 0) rotate(360deg)
  }
}

@keyframes ld-orbit {
  0% {
    -webkit-transform: translate(30%, 0) rotate(0);
    transform: translate(30%, 0) rotate(0)
  }
  12.5% {
    -webkit-transform: translate(21%, 21%) rotate(45deg);
    transform: translate(21%, 21%) rotate(45deg)
  }
  25% {
    -webkit-transform: translate(0, 30%) rotate(90deg);
    transform: translate(0, 30%) rotate(90deg)
  }
  37.5% {
    -webkit-transform: translate(-21%, 21%) rotate(135deg);
    transform: translate(-21%, 21%) rotate(135deg)
  }
  50% {
    -webkit-transform: translate(-30%, 0) rotate(180deg);
    transform: translate(-30%, 0) rotate(180deg)
  }
  62.5% {
    -webkit-transform: translate(-21%, -21%) rotate(225deg);
    transform: translate(-21%, -21%) rotate(225deg)
  }
  75% {
    -webkit-transform: translate(0, -30%) rotate(270deg);
    transform: translate(0, -30%) rotate(270deg)
  }
  87.5% {
    -webkit-transform: translate(21%, -21%) rotate(315deg);
    transform: translate(21%, -21%) rotate(315deg)
  }
  100% {
    -webkit-transform: translate(30%, 0) rotate(360deg);
    transform: translate(30%, 0) rotate(360deg)
  }
}

.ld.ld-orbit {
  -webkit-animation: ld-orbit 1s infinite linear;
  animation: ld-orbit 1s infinite linear
}

@-webkit-keyframes ld-fade {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-moz-keyframes ld-fade {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-webkit-keyframes ld-fade {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-o-keyframes ld-fade {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes ld-fade {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.ld.ld-fade {
  -webkit-animation: ld-fade 1s infinite linear;
  animation: ld-fade 1s infinite linear
}

@-webkit-keyframes ld-surprise {
  0% {
    -webkit-transform: skewX(0) scale(1);
    transform: skewX(0) scale(1)
  }
  10% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  20% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  30% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  40% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  50% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  60% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  70% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  80% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  90% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  100% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
}

@-moz-keyframes ld-surprise {
  0% {
    -webkit-transform: skewX(0) scale(1);
    transform: skewX(0) scale(1)
  }
  10% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  20% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  30% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  40% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  50% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  60% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  70% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  80% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  90% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  100% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
}

@-webkit-keyframes ld-surprise {
  0% {
    -webkit-transform: skewX(0) scale(1);
    transform: skewX(0) scale(1)
  }
  10% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  20% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  30% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  40% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  50% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  60% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  70% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  80% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  90% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  100% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
}

@-o-keyframes ld-surprise {
  0% {
    -webkit-transform: skewX(0) scale(1);
    transform: skewX(0) scale(1)
  }
  10% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  20% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  30% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  40% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  50% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  60% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  70% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  80% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  90% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  100% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
}

@keyframes ld-surprise {
  0% {
    -webkit-transform: skewX(0) scale(1);
    transform: skewX(0) scale(1)
  }
  10% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  20% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8)
  }
  30% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  40% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  50% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  60% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  70% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  80% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
  90% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3)
  }
  100% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3)
  }
}

.ld.ld-surprise {
  -webkit-animation: ld-surprise 1s infinite linear;
  animation: ld-surprise 1s infinite linear
}

@-webkit-keyframes ld-shake {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  16.6% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  33.3% {
    -webkit-transform: translate(25%, 0);
    transform: translate(25%, 0)
  }
  50% {
    -webkit-transform: translate(-12%, 0);
    transform: translate(-12%, 0)
  }
  66.6% {
    -webkit-transform: translate(6%, 0);
    transform: translate(6%, 0)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0);
    transform: translate(-2.5%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-moz-keyframes ld-shake {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  16.6% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  33.3% {
    -webkit-transform: translate(25%, 0);
    transform: translate(25%, 0)
  }
  50% {
    -webkit-transform: translate(-12%, 0);
    transform: translate(-12%, 0)
  }
  66.6% {
    -webkit-transform: translate(6%, 0);
    transform: translate(6%, 0)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0);
    transform: translate(-2.5%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-webkit-keyframes ld-shake {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  16.6% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  33.3% {
    -webkit-transform: translate(25%, 0);
    transform: translate(25%, 0)
  }
  50% {
    -webkit-transform: translate(-12%, 0);
    transform: translate(-12%, 0)
  }
  66.6% {
    -webkit-transform: translate(6%, 0);
    transform: translate(6%, 0)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0);
    transform: translate(-2.5%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-o-keyframes ld-shake {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  16.6% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  33.3% {
    -webkit-transform: translate(25%, 0);
    transform: translate(25%, 0)
  }
  50% {
    -webkit-transform: translate(-12%, 0);
    transform: translate(-12%, 0)
  }
  66.6% {
    -webkit-transform: translate(6%, 0);
    transform: translate(6%, 0)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0);
    transform: translate(-2.5%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes ld-shake {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  16.6% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0)
  }
  33.3% {
    -webkit-transform: translate(25%, 0);
    transform: translate(25%, 0)
  }
  50% {
    -webkit-transform: translate(-12%, 0);
    transform: translate(-12%, 0)
  }
  66.6% {
    -webkit-transform: translate(6%, 0);
    transform: translate(6%, 0)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0);
    transform: translate(-2.5%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

.ld.ld-shake {
  -webkit-animation: ld-shake 1s infinite linear;
  animation: ld-shake 1s infinite linear
}

@-webkit-keyframes ld-jelly {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  16.6% {
    -webkit-transform: translate(-30%, 0) skewX(30deg);
    transform: translate(-30%, 0) skewX(30deg)
  }
  33.3% {
    -webkit-transform: translate(25%, 0) skewX(-20deg);
    transform: translate(25%, 0) skewX(-20deg)
  }
  50% {
    -webkit-transform: translate(-12%, 0) skewX(10deg);
    transform: translate(-12%, 0) skewX(10deg)
  }
  66.6% {
    -webkit-transform: translate(6%, 0) skewX(-5deg);
    transform: translate(6%, 0) skewX(-5deg)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0) skewX(2deg);
    transform: translate(-2.5%, 0) skewX(2deg)
  }
  100% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
}

@-moz-keyframes ld-jelly {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  16.6% {
    -webkit-transform: translate(-30%, 0) skewX(30deg);
    transform: translate(-30%, 0) skewX(30deg)
  }
  33.3% {
    -webkit-transform: translate(25%, 0) skewX(-20deg);
    transform: translate(25%, 0) skewX(-20deg)
  }
  50% {
    -webkit-transform: translate(-12%, 0) skewX(10deg);
    transform: translate(-12%, 0) skewX(10deg)
  }
  66.6% {
    -webkit-transform: translate(6%, 0) skewX(-5deg);
    transform: translate(6%, 0) skewX(-5deg)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0) skewX(2deg);
    transform: translate(-2.5%, 0) skewX(2deg)
  }
  100% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
}

@-webkit-keyframes ld-jelly {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  16.6% {
    -webkit-transform: translate(-30%, 0) skewX(30deg);
    transform: translate(-30%, 0) skewX(30deg)
  }
  33.3% {
    -webkit-transform: translate(25%, 0) skewX(-20deg);
    transform: translate(25%, 0) skewX(-20deg)
  }
  50% {
    -webkit-transform: translate(-12%, 0) skewX(10deg);
    transform: translate(-12%, 0) skewX(10deg)
  }
  66.6% {
    -webkit-transform: translate(6%, 0) skewX(-5deg);
    transform: translate(6%, 0) skewX(-5deg)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0) skewX(2deg);
    transform: translate(-2.5%, 0) skewX(2deg)
  }
  100% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
}

@-o-keyframes ld-jelly {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  16.6% {
    -webkit-transform: translate(-30%, 0) skewX(30deg);
    transform: translate(-30%, 0) skewX(30deg)
  }
  33.3% {
    -webkit-transform: translate(25%, 0) skewX(-20deg);
    transform: translate(25%, 0) skewX(-20deg)
  }
  50% {
    -webkit-transform: translate(-12%, 0) skewX(10deg);
    transform: translate(-12%, 0) skewX(10deg)
  }
  66.6% {
    -webkit-transform: translate(6%, 0) skewX(-5deg);
    transform: translate(6%, 0) skewX(-5deg)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0) skewX(2deg);
    transform: translate(-2.5%, 0) skewX(2deg)
  }
  100% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
}

@keyframes ld-jelly {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  16.6% {
    -webkit-transform: translate(-30%, 0) skewX(30deg);
    transform: translate(-30%, 0) skewX(30deg)
  }
  33.3% {
    -webkit-transform: translate(25%, 0) skewX(-20deg);
    transform: translate(25%, 0) skewX(-20deg)
  }
  50% {
    -webkit-transform: translate(-12%, 0) skewX(10deg);
    transform: translate(-12%, 0) skewX(10deg)
  }
  66.6% {
    -webkit-transform: translate(6%, 0) skewX(-5deg);
    transform: translate(6%, 0) skewX(-5deg)
  }
  83.3% {
    -webkit-transform: translate(-2.5%, 0) skewX(2deg);
    transform: translate(-2.5%, 0) skewX(2deg)
  }
  100% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
}

.ld.ld-jelly {
  -webkit-animation: ld-jelly 1s infinite linear;
  animation: ld-jelly 1s infinite linear
}

@-webkit-keyframes ld-float {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: linear
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
  30% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  50% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
}

@-moz-keyframes ld-float {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: linear
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
  30% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  50% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
}

@-webkit-keyframes ld-float {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: linear
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
  30% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  50% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
}

@-o-keyframes ld-float {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: linear
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
  30% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  50% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
}

@keyframes ld-float {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: linear
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
  30% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  50% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3)
  }
}

.ld.ld-float {
  -webkit-animation: ld-float 1s infinite;
  animation: ld-float 1s infinite
}

@-webkit-keyframes ld-rotate {
  0%, 33%, 66%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  16%, 50%, 83% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
  16% {
    -webkit-transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1)
  }
  33% {
    -webkit-transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1)
  }
  50% {
    -webkit-transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2);
    transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2)
  }
  66% {
    -webkit-transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1)
  }
  83% {
    -webkit-transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1)
  }
  100% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
}

@-moz-keyframes ld-rotate {
  0%, 33%, 66%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  16%, 50%, 83% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
  16% {
    -webkit-transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1)
  }
  33% {
    -webkit-transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1)
  }
  50% {
    -webkit-transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2);
    transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2)
  }
  66% {
    -webkit-transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1)
  }
  83% {
    -webkit-transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1)
  }
  100% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
}

@-webkit-keyframes ld-rotate {
  0%, 33%, 66%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  16%, 50%, 83% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
  16% {
    -webkit-transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1)
  }
  33% {
    -webkit-transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1)
  }
  50% {
    -webkit-transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2);
    transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2)
  }
  66% {
    -webkit-transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1)
  }
  83% {
    -webkit-transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1)
  }
  100% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
}

@-o-keyframes ld-rotate {
  0%, 33%, 66%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  16%, 50%, 83% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
  16% {
    -webkit-transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1)
  }
  33% {
    -webkit-transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1)
  }
  50% {
    -webkit-transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2);
    transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2)
  }
  66% {
    -webkit-transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1)
  }
  83% {
    -webkit-transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1)
  }
  100% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
}

@keyframes ld-rotate {
  0%, 33%, 66%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  16%, 50%, 83% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
  16% {
    -webkit-transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(45deg) skewY(0) scaleX(1) scaleY(1)
  }
  33% {
    -webkit-transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(45deg) scaleX(1) scaleY(1)
  }
  50% {
    -webkit-transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2);
    transform: skewX(0) skewY(0) scaleX(0.5) scaleY(2)
  }
  66% {
    -webkit-transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1);
    transform: skewX(0) skewY(-45deg) scaleX(1) scaleY(1)
  }
  83% {
    -webkit-transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1);
    transform: skewX(-45deg) skewY(0) scaleX(1) scaleY(1)
  }
  100% {
    -webkit-transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5);
    transform: skewX(0) skewY(0) scaleX(2) scaleY(0.5)
  }
}

.ld.ld-rotate {
  -webkit-animation: ld-rotate 1s infinite;
  animation: ld-rotate 1s infinite
}

@-webkit-keyframes ld-blur {
  0% {
    filter: blur(0)
  }
  50% {
    filter: blur(5px)
  }
  100% {
    filter: blur(0)
  }
}

@-moz-keyframes ld-blur {
  0% {
    filter: blur(0)
  }
  50% {
    filter: blur(5px)
  }
  100% {
    filter: blur(0)
  }
}

@-webkit-keyframes ld-blur {
  0% {
    filter: blur(0)
  }
  50% {
    filter: blur(5px)
  }
  100% {
    filter: blur(0)
  }
}

@-o-keyframes ld-blur {
  0% {
    filter: blur(0)
  }
  50% {
    filter: blur(5px)
  }
  100% {
    filter: blur(0)
  }
}

@keyframes ld-blur {
  0% {
    filter: blur(0)
  }
  50% {
    filter: blur(5px)
  }
  100% {
    filter: blur(0)
  }
}

.ld.ld-blur {
  -webkit-animation: ld-blur 1s infinite;
  animation: ld-blur 1s infinite
}

@-webkit-keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0) scale(0);
    transform: rotate(0) scale(0);
    opacity: 1
  }
  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0
  }
}

@-moz-keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0) scale(0);
    transform: rotate(0) scale(0);
    opacity: 1
  }
  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0
  }
}

@-webkit-keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0) scale(0);
    transform: rotate(0) scale(0);
    opacity: 1
  }
  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0
  }
}

@-o-keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0) scale(0);
    transform: rotate(0) scale(0);
    opacity: 1
  }
  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0
  }
}

@keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0) scale(0);
    transform: rotate(0) scale(0);
    opacity: 1
  }
  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1
  }
  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0
  }
}

.ld.ld-vortex-out {
  -webkit-animation: ld-vortex-out 2s infinite;
  animation: ld-vortex-out 2s infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
}

@-webkit-keyframes ld-vortex-in {
  0% {
    -webkit-transform: rotate(1800deg) scale(3);
    transform: rotate(1800deg) scale(3);
    opacity: 0
  }
  60% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-moz-keyframes ld-vortex-in {
  0% {
    -webkit-transform: rotate(1800deg) scale(3);
    transform: rotate(1800deg) scale(3);
    opacity: 0
  }
  60% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-webkit-keyframes ld-vortex-in {
  0% {
    -webkit-transform: rotate(1800deg) scale(3);
    transform: rotate(1800deg) scale(3);
    opacity: 0
  }
  60% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-o-keyframes ld-vortex-in {
  0% {
    -webkit-transform: rotate(1800deg) scale(3);
    transform: rotate(1800deg) scale(3);
    opacity: 0
  }
  60% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes ld-vortex-in {
  0% {
    -webkit-transform: rotate(1800deg) scale(3);
    transform: rotate(1800deg) scale(3);
    opacity: 0
  }
  60% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.ld.ld-vortex-in {
  -webkit-animation: ld-vortex-in 2s infinite;
  animation: ld-vortex-in 2s infinite;
  animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7)
}

@-webkit-keyframes ld-slide-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  50% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-moz-keyframes ld-slide-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  50% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-webkit-keyframes ld-slide-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  50% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-o-keyframes ld-slide-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  50% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes ld-slide-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  50% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

.ld.ld-slide-rtl {
  -webkit-animation: ld-slide-rtl 1s infinite;
  animation: ld-slide-rtl 1s infinite
}

@-webkit-keyframes ld-slide-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  50% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-moz-keyframes ld-slide-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  50% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-webkit-keyframes ld-slide-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  50% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-o-keyframes ld-slide-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  50% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes ld-slide-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0)
  }
  50% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

.ld.ld-slide-ltr {
  -webkit-animation: ld-slide-ltr 1s infinite;
  animation: ld-slide-ltr 1s infinite
}

@-webkit-keyframes ld-slide-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  50% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-moz-keyframes ld-slide-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  50% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-webkit-keyframes ld-slide-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  50% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-o-keyframes ld-slide-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  50% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes ld-slide-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  50% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

.ld.ld-slide-btt {
  -webkit-animation: ld-slide-btt 1s infinite;
  animation: ld-slide-btt 1s infinite
}

@-webkit-keyframes ld-slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  50% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-moz-keyframes ld-slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  50% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-webkit-keyframes ld-slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  50% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-o-keyframes ld-slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  50% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes ld-slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  49.9% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%)
  }
  50% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

.ld.ld-slide-ttb {
  -webkit-animation: ld-slide-ttb 1s infinite;
  animation: ld-slide-ttb 1s infinite
}

@-webkit-keyframes ld-jump {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out
  }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in
  }
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  14% {
    -webkit-transform: translateY(-27%);
    transform: translateY(-27%)
  }
  28% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  38% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%)
  }
  48% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  56% {
    -webkit-transform: translateY(-16%);
    transform: translateY(-16%)
  }
  64% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  70% {
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%)
  }
  76% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  81% {
    -webkit-transform: translateY(-7.5%);
    transform: translateY(-7.5%)
  }
  86% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  90% {
    -webkit-transform: translateY(-3%);
    transform: translateY(-3%)
  }
  93% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  97% {
    -webkit-transform: translateY(-1.5%);
    transform: translateY(-1.5%)
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-moz-keyframes ld-jump {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out
  }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in
  }
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  14% {
    -webkit-transform: translateY(-27%);
    transform: translateY(-27%)
  }
  28% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  38% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%)
  }
  48% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  56% {
    -webkit-transform: translateY(-16%);
    transform: translateY(-16%)
  }
  64% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  70% {
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%)
  }
  76% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  81% {
    -webkit-transform: translateY(-7.5%);
    transform: translateY(-7.5%)
  }
  86% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  90% {
    -webkit-transform: translateY(-3%);
    transform: translateY(-3%)
  }
  93% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  97% {
    -webkit-transform: translateY(-1.5%);
    transform: translateY(-1.5%)
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes ld-jump {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out
  }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in
  }
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  14% {
    -webkit-transform: translateY(-27%);
    transform: translateY(-27%)
  }
  28% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  38% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%)
  }
  48% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  56% {
    -webkit-transform: translateY(-16%);
    transform: translateY(-16%)
  }
  64% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  70% {
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%)
  }
  76% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  81% {
    -webkit-transform: translateY(-7.5%);
    transform: translateY(-7.5%)
  }
  86% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  90% {
    -webkit-transform: translateY(-3%);
    transform: translateY(-3%)
  }
  93% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  97% {
    -webkit-transform: translateY(-1.5%);
    transform: translateY(-1.5%)
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-o-keyframes ld-jump {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out
  }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in
  }
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  14% {
    -webkit-transform: translateY(-27%);
    transform: translateY(-27%)
  }
  28% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  38% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%)
  }
  48% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  56% {
    -webkit-transform: translateY(-16%);
    transform: translateY(-16%)
  }
  64% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  70% {
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%)
  }
  76% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  81% {
    -webkit-transform: translateY(-7.5%);
    transform: translateY(-7.5%)
  }
  86% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  90% {
    -webkit-transform: translateY(-3%);
    transform: translateY(-3%)
  }
  93% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  97% {
    -webkit-transform: translateY(-1.5%);
    transform: translateY(-1.5%)
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes ld-jump {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out
  }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in
  }
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  14% {
    -webkit-transform: translateY(-27%);
    transform: translateY(-27%)
  }
  28% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  38% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%)
  }
  48% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  56% {
    -webkit-transform: translateY(-16%);
    transform: translateY(-16%)
  }
  64% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  70% {
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%)
  }
  76% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  81% {
    -webkit-transform: translateY(-7.5%);
    transform: translateY(-7.5%)
  }
  86% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  90% {
    -webkit-transform: translateY(-3%);
    transform: translateY(-3%)
  }
  93% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  97% {
    -webkit-transform: translateY(-1.5%);
    transform: translateY(-1.5%)
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

.ld.ld-jump {
  -webkit-animation: ld-jump 1.5s ease-in infinite;
  animation: ld-jump 1.5s ease-in infinite
}

@-webkit-keyframes ld-bounce {
  0%, 90% {
    animation-timing-function: linear
  }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
  10% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  50% {
    -webkit-transform: translate(0, -37%) scaleY(1.1);
    transform: translate(0, -37%) scaleY(1.1)
  }
  90% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  100% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
}

@-moz-keyframes ld-bounce {
  0%, 90% {
    animation-timing-function: linear
  }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
  10% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  50% {
    -webkit-transform: translate(0, -37%) scaleY(1.1);
    transform: translate(0, -37%) scaleY(1.1)
  }
  90% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  100% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
}

@-webkit-keyframes ld-bounce {
  0%, 90% {
    animation-timing-function: linear
  }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
  10% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  50% {
    -webkit-transform: translate(0, -37%) scaleY(1.1);
    transform: translate(0, -37%) scaleY(1.1)
  }
  90% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  100% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
}

@-o-keyframes ld-bounce {
  0%, 90% {
    animation-timing-function: linear
  }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
  10% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  50% {
    -webkit-transform: translate(0, -37%) scaleY(1.1);
    transform: translate(0, -37%) scaleY(1.1)
  }
  90% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  100% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
}

@keyframes ld-bounce {
  0%, 90% {
    animation-timing-function: linear
  }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
  10% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  50% {
    -webkit-transform: translate(0, -37%) scaleY(1.1);
    transform: translate(0, -37%) scaleY(1.1)
  }
  90% {
    -webkit-transform: translate(0, 5%) scaleY(1.1);
    transform: translate(0, 5%) scaleY(1.1)
  }
  100% {
    -webkit-transform: translate(0, 30%) scaleY(0.5);
    transform: translate(0, 30%) scaleY(0.5)
  }
}

.ld.ld-bounce {
  -webkit-animation: ld-bounce 1s infinite;
  animation: ld-bounce 1s infinite
}

@-webkit-keyframes ld-bounce-rtl {
  0% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  25% {
    -webkit-transform: translate(80%, 0);
    transform: translate(80%, 0)
  }
  37.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  75% {
    -webkit-transform: translate(-80%, 0);
    transform: translate(-80%, 0)
  }
  87.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  100% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
}

@-moz-keyframes ld-bounce-rtl {
  0% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  25% {
    -webkit-transform: translate(80%, 0);
    transform: translate(80%, 0)
  }
  37.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  75% {
    -webkit-transform: translate(-80%, 0);
    transform: translate(-80%, 0)
  }
  87.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  100% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
}

@-webkit-keyframes ld-bounce-rtl {
  0% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  25% {
    -webkit-transform: translate(80%, 0);
    transform: translate(80%, 0)
  }
  37.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  75% {
    -webkit-transform: translate(-80%, 0);
    transform: translate(-80%, 0)
  }
  87.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  100% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
}

@-o-keyframes ld-bounce-rtl {
  0% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  25% {
    -webkit-transform: translate(80%, 0);
    transform: translate(80%, 0)
  }
  37.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  75% {
    -webkit-transform: translate(-80%, 0);
    transform: translate(-80%, 0)
  }
  87.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  100% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
}

@keyframes ld-bounce-rtl {
  0% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  25% {
    -webkit-transform: translate(80%, 0);
    transform: translate(80%, 0)
  }
  37.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  75% {
    -webkit-transform: translate(-80%, 0);
    transform: translate(-80%, 0)
  }
  87.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  100% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
}

.ld.ld-bounce-rtl {
  -webkit-animation: ld-bounce-rtl 1s infinite linear;
  animation: ld-bounce-rtl 1s infinite linear
}

@-webkit-keyframes ld-bounce-ltr {
  0% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  25% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
  }
  37.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  75% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0)
  }
  87.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  100% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
}

@-moz-keyframes ld-bounce-ltr {
  0% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  25% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
  }
  37.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  75% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0)
  }
  87.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  100% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
}

@-webkit-keyframes ld-bounce-ltr {
  0% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  25% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
  }
  37.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  75% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0)
  }
  87.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  100% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
}

@-o-keyframes ld-bounce-ltr {
  0% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  25% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
  }
  37.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  75% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0)
  }
  87.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  100% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
}

@keyframes ld-bounce-ltr {
  0% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%)
  }
  12.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%)
  }
  25% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
  }
  37.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%)
  }
  50% {
    -webkit-transform: translate(0, -40%);
    transform: translate(0, -40%)
  }
  62.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%)
  }
  75% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0)
  }
  87.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%)
  }
  100% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%)
  }
}

.ld.ld-bounce-ltr {
  -webkit-animation: ld-bounce-ltr 1s infinite linear;
  animation: ld-bounce-ltr 1s infinite linear
}

@-webkit-keyframes ld-bounce-a {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  12.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  25% {
    -webkit-transform: translate(10%, 0);
    transform: translate(10%, 0)
  }
  37.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  62.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  75% {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0)
  }
  87.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-moz-keyframes ld-bounce-a {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  12.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  25% {
    -webkit-transform: translate(10%, 0);
    transform: translate(10%, 0)
  }
  37.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  62.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  75% {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0)
  }
  87.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-webkit-keyframes ld-bounce-a {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  12.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  25% {
    -webkit-transform: translate(10%, 0);
    transform: translate(10%, 0)
  }
  37.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  62.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  75% {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0)
  }
  87.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-o-keyframes ld-bounce-a {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  12.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  25% {
    -webkit-transform: translate(10%, 0);
    transform: translate(10%, 0)
  }
  37.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  62.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  75% {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0)
  }
  87.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes ld-bounce-a {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1)
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6)
  }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  12.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  25% {
    -webkit-transform: translate(10%, 0);
    transform: translate(10%, 0)
  }
  37.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%)
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  62.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  75% {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0)
  }
  87.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%)
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

.ld.ld-bounce-a {
  -webkit-animation: ld-bounce-a 2s infinite;
  animation: ld-bounce-a 2s infinite
}

@-webkit-keyframes ld-rush-ltr {
  0% {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(40%, 0) skewX(-35deg);
    transform: translate(40%, 0) skewX(-35deg)
  }
  45% {
    -webkit-transform: translate(-20%, 0) skewX(15deg);
    transform: translate(-20%, 0) skewX(15deg)
  }
  60% {
    -webkit-transform: translate(10%, 0) skewX(-7deg);
    transform: translate(10%, 0) skewX(-7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(250%, 0) skewX(45deg);
    transform: translate(250%, 0) skewX(45deg)
  }
}

@-moz-keyframes ld-rush-ltr {
  0% {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(40%, 0) skewX(-35deg);
    transform: translate(40%, 0) skewX(-35deg)
  }
  45% {
    -webkit-transform: translate(-20%, 0) skewX(15deg);
    transform: translate(-20%, 0) skewX(15deg)
  }
  60% {
    -webkit-transform: translate(10%, 0) skewX(-7deg);
    transform: translate(10%, 0) skewX(-7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(250%, 0) skewX(45deg);
    transform: translate(250%, 0) skewX(45deg)
  }
}

@-webkit-keyframes ld-rush-ltr {
  0% {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(40%, 0) skewX(-35deg);
    transform: translate(40%, 0) skewX(-35deg)
  }
  45% {
    -webkit-transform: translate(-20%, 0) skewX(15deg);
    transform: translate(-20%, 0) skewX(15deg)
  }
  60% {
    -webkit-transform: translate(10%, 0) skewX(-7deg);
    transform: translate(10%, 0) skewX(-7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(250%, 0) skewX(45deg);
    transform: translate(250%, 0) skewX(45deg)
  }
}

@-o-keyframes ld-rush-ltr {
  0% {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(40%, 0) skewX(-35deg);
    transform: translate(40%, 0) skewX(-35deg)
  }
  45% {
    -webkit-transform: translate(-20%, 0) skewX(15deg);
    transform: translate(-20%, 0) skewX(15deg)
  }
  60% {
    -webkit-transform: translate(10%, 0) skewX(-7deg);
    transform: translate(10%, 0) skewX(-7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(250%, 0) skewX(45deg);
    transform: translate(250%, 0) skewX(45deg)
  }
}

@keyframes ld-rush-ltr {
  0% {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(40%, 0) skewX(-35deg);
    transform: translate(40%, 0) skewX(-35deg)
  }
  45% {
    -webkit-transform: translate(-20%, 0) skewX(15deg);
    transform: translate(-20%, 0) skewX(15deg)
  }
  60% {
    -webkit-transform: translate(10%, 0) skewX(-7deg);
    transform: translate(10%, 0) skewX(-7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(250%, 0) skewX(45deg);
    transform: translate(250%, 0) skewX(45deg)
  }
}

.ld.ld-rush-ltr {
  -webkit-animation: ld-rush-ltr 1.5s infinite linear;
  animation: ld-rush-ltr 1.5s infinite linear
}

@-webkit-keyframes ld-rush-rtl {
  0% {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(-40%, 0) skewX(35deg);
    transform: translate(-40%, 0) skewX(35deg)
  }
  45% {
    -webkit-transform: translate(20%, 0) skewX(-15deg);
    transform: translate(20%, 0) skewX(-15deg)
  }
  60% {
    -webkit-transform: translate(-10%, 0) skewX(7deg);
    transform: translate(-10%, 0) skewX(7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(-250%, 0) skewX(-45deg);
    transform: translate(-250%, 0) skewX(-45deg)
  }
}

@-moz-keyframes ld-rush-rtl {
  0% {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(-40%, 0) skewX(35deg);
    transform: translate(-40%, 0) skewX(35deg)
  }
  45% {
    -webkit-transform: translate(20%, 0) skewX(-15deg);
    transform: translate(20%, 0) skewX(-15deg)
  }
  60% {
    -webkit-transform: translate(-10%, 0) skewX(7deg);
    transform: translate(-10%, 0) skewX(7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(-250%, 0) skewX(-45deg);
    transform: translate(-250%, 0) skewX(-45deg)
  }
}

@-webkit-keyframes ld-rush-rtl {
  0% {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(-40%, 0) skewX(35deg);
    transform: translate(-40%, 0) skewX(35deg)
  }
  45% {
    -webkit-transform: translate(20%, 0) skewX(-15deg);
    transform: translate(20%, 0) skewX(-15deg)
  }
  60% {
    -webkit-transform: translate(-10%, 0) skewX(7deg);
    transform: translate(-10%, 0) skewX(7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(-250%, 0) skewX(-45deg);
    transform: translate(-250%, 0) skewX(-45deg)
  }
}

@-o-keyframes ld-rush-rtl {
  0% {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(-40%, 0) skewX(35deg);
    transform: translate(-40%, 0) skewX(35deg)
  }
  45% {
    -webkit-transform: translate(20%, 0) skewX(-15deg);
    transform: translate(20%, 0) skewX(-15deg)
  }
  60% {
    -webkit-transform: translate(-10%, 0) skewX(7deg);
    transform: translate(-10%, 0) skewX(7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(-250%, 0) skewX(-45deg);
    transform: translate(-250%, 0) skewX(-45deg)
  }
}

@keyframes ld-rush-rtl {
  0% {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  30% {
    -webkit-transform: translate(-40%, 0) skewX(35deg);
    transform: translate(-40%, 0) skewX(35deg)
  }
  45% {
    -webkit-transform: translate(20%, 0) skewX(-15deg);
    transform: translate(20%, 0) skewX(-15deg)
  }
  60% {
    -webkit-transform: translate(-10%, 0) skewX(7deg);
    transform: translate(-10%, 0) skewX(7deg)
  }
  80% {
    -webkit-transform: translate(0, 0) skewX(0);
    transform: translate(0, 0) skewX(0)
  }
  100% {
    -webkit-transform: translate(-250%, 0) skewX(-45deg);
    transform: translate(-250%, 0) skewX(-45deg)
  }
}

.ld.ld-rush-rtl {
  -webkit-animation: ld-rush-rtl 1.5s infinite linear;
  animation: ld-rush-rtl 1.5s infinite linear
}

@-webkit-keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  25% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
    transform: rotateY(180deg) rotateX(180deg)
  }
  75% {
    -webkit-transform: rotateY(0) rotateX(180deg);
    transform: rotateY(0) rotateX(180deg)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@-moz-keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  25% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
    transform: rotateY(180deg) rotateX(180deg)
  }
  75% {
    -webkit-transform: rotateY(0) rotateX(180deg);
    transform: rotateY(0) rotateX(180deg)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@-webkit-keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  25% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
    transform: rotateY(180deg) rotateX(180deg)
  }
  75% {
    -webkit-transform: rotateY(0) rotateX(180deg);
    transform: rotateY(0) rotateX(180deg)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@-o-keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  25% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
    transform: rotateY(180deg) rotateX(180deg)
  }
  75% {
    -webkit-transform: rotateY(0) rotateX(180deg);
    transform: rotateY(0) rotateX(180deg)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  25% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
    transform: rotateY(180deg) rotateX(180deg)
  }
  75% {
    -webkit-transform: rotateY(0) rotateX(180deg);
    transform: rotateY(0) rotateX(180deg)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

.ld.ld-flip {
  -webkit-animation: ld-flip 2s infinite;
  animation: ld-flip 2s infinite
}

@-webkit-keyframes ld-flip-h {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@-moz-keyframes ld-flip-h {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@-webkit-keyframes ld-flip-h {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@-o-keyframes ld-flip-h {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

@keyframes ld-flip-h {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(0);
    transform: rotateY(180deg) rotateX(0)
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0);
    transform: rotateY(0) rotateX(0)
  }
}

.ld.ld-flip-h {
  -webkit-animation: ld-flip-h 1s infinite;
  animation: ld-flip-h 1s infinite
}

@-webkit-keyframes ld-flip-v {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
  50% {
    -webkit-transform: rotateX(180deg) rotateY(0);
    transform: rotateX(180deg) rotateY(0)
  }
  100% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
}

@-moz-keyframes ld-flip-v {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
  50% {
    -webkit-transform: rotateX(180deg) rotateY(0);
    transform: rotateX(180deg) rotateY(0)
  }
  100% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
}

@-webkit-keyframes ld-flip-v {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
  50% {
    -webkit-transform: rotateX(180deg) rotateY(0);
    transform: rotateX(180deg) rotateY(0)
  }
  100% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
}

@-o-keyframes ld-flip-v {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
  50% {
    -webkit-transform: rotateX(180deg) rotateY(0);
    transform: rotateX(180deg) rotateY(0)
  }
  100% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
}

@keyframes ld-flip-v {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
  50% {
    -webkit-transform: rotateX(180deg) rotateY(0);
    transform: rotateX(180deg) rotateY(0)
  }
  100% {
    -webkit-transform: rotateX(0) rotateY(0);
    transform: rotateX(0) rotateY(0)
  }
}

.ld.ld-flip-v {
  -webkit-animation: ld-flip-v 1s infinite;
  animation: ld-flip-v 1s infinite
}

@-webkit-keyframes ld-coin-h {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0)
  }
  50% {
    -webkit-transform: rotateY(1800deg);
    transform: rotateY(1800deg)
  }
  100% {
    -webkit-transform: rotateY(3600deg);
    transform: rotateY(3600deg)
  }
}

@-moz-keyframes ld-coin-h {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0)
  }
  50% {
    -webkit-transform: rotateY(1800deg);
    transform: rotateY(1800deg)
  }
  100% {
    -webkit-transform: rotateY(3600deg);
    transform: rotateY(3600deg)
  }
}

@-webkit-keyframes ld-coin-h {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0)
  }
  50% {
    -webkit-transform: rotateY(1800deg);
    transform: rotateY(1800deg)
  }
  100% {
    -webkit-transform: rotateY(3600deg);
    transform: rotateY(3600deg)
  }
}

@-o-keyframes ld-coin-h {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0)
  }
  50% {
    -webkit-transform: rotateY(1800deg);
    transform: rotateY(1800deg)
  }
  100% {
    -webkit-transform: rotateY(3600deg);
    transform: rotateY(3600deg)
  }
}

@keyframes ld-coin-h {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0)
  }
  50% {
    -webkit-transform: rotateY(1800deg);
    transform: rotateY(1800deg)
  }
  100% {
    -webkit-transform: rotateY(3600deg);
    transform: rotateY(3600deg)
  }
}

.ld.ld-coin-h {
  -webkit-animation: ld-coin-h 2s infinite;
  animation: ld-coin-h 2s infinite
}

@-webkit-keyframes ld-coin-v {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0)
  }
  50% {
    -webkit-transform: rotateX(1800deg);
    transform: rotateX(1800deg)
  }
  100% {
    -webkit-transform: rotateX(3600deg);
    transform: rotateX(3600deg)
  }
}

@-moz-keyframes ld-coin-v {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0)
  }
  50% {
    -webkit-transform: rotateX(1800deg);
    transform: rotateX(1800deg)
  }
  100% {
    -webkit-transform: rotateX(3600deg);
    transform: rotateX(3600deg)
  }
}

@-webkit-keyframes ld-coin-v {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0)
  }
  50% {
    -webkit-transform: rotateX(1800deg);
    transform: rotateX(1800deg)
  }
  100% {
    -webkit-transform: rotateX(3600deg);
    transform: rotateX(3600deg)
  }
}

@-o-keyframes ld-coin-v {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0)
  }
  50% {
    -webkit-transform: rotateX(1800deg);
    transform: rotateX(1800deg)
  }
  100% {
    -webkit-transform: rotateX(3600deg);
    transform: rotateX(3600deg)
  }
}

@keyframes ld-coin-v {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1)
  }
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0)
  }
  50% {
    -webkit-transform: rotateX(1800deg);
    transform: rotateX(1800deg)
  }
  100% {
    -webkit-transform: rotateX(3600deg);
    transform: rotateX(3600deg)
  }
}

.ld.ld-coin-v {
  -webkit-animation: ld-coin-v 2s infinite;
  animation: ld-coin-v 2s infinite
}

@-webkit-keyframes ld-broadcast {
  0% {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.9)
  }
  19% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7)
  }
  20% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8)
  }
  39% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.6)
  }
  40% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.7)
  }
  60% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@-moz-keyframes ld-broadcast {
  0% {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.9)
  }
  19% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7)
  }
  20% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8)
  }
  39% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.6)
  }
  40% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.7)
  }
  60% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@-webkit-keyframes ld-broadcast {
  0% {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.9)
  }
  19% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7)
  }
  20% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8)
  }
  39% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.6)
  }
  40% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.7)
  }
  60% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@-o-keyframes ld-broadcast {
  0% {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.9)
  }
  19% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7)
  }
  20% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8)
  }
  39% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.6)
  }
  40% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.7)
  }
  60% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@keyframes ld-broadcast {
  0% {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.9)
  }
  19% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7)
  }
  20% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8)
  }
  39% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.6)
  }
  40% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.7)
  }
  60% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

.ld.ld-broadcast {
  -webkit-animation: ld-broadcast 1s infinite ease-out;
  animation: ld-broadcast 1s infinite ease-out;
  border-radius: 50%
}

@-webkit-keyframes ld-shadow {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.9)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@-moz-keyframes ld-shadow {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.9)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@-webkit-keyframes ld-shadow {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.9)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@-o-keyframes ld-shadow {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.9)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

@keyframes ld-shadow {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  50% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1)
  }
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.9)
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2)
  }
}

.ld.ld-shadow {
  -webkit-animation: ld-shadow 1s infinite;
  animation: ld-shadow 1s infinite;
  border-radius: 50%
}

@-webkit-keyframes ld-shadow-a {
  0% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  8.33% {
    box-shadow: 3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  16.67% {
    box-shadow: 2px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  25% {
    box-shadow: 0 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  33.33% {
    box-shadow: -1px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  41.67% {
    box-shadow: -3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  50% {
    box-shadow: -3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  58.33% {
    box-shadow: -3px -1px 0 6px rgba(0, 0, 0, 0.5)
  }
  66.67% {
    box-shadow: -2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  75% {
    box-shadow: 0 -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  83.33% {
    box-shadow: 2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  91.67% {
    box-shadow: 3px -2px 0 6px rgba(0, 0, 0, 0.5)
  }
  100% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
}

@-moz-keyframes ld-shadow-a {
  0 {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  8.33% {
    box-shadow: 3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  16.67% {
    box-shadow: 2px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  25% {
    box-shadow: 0 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  33.33% {
    box-shadow: -1px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  41.67% {
    box-shadow: -3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  50% {
    box-shadow: -3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  58.33% {
    box-shadow: -3px -1px 0 6px rgba(0, 0, 0, 0.5)
  }
  66.67% {
    box-shadow: -2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  75% {
    box-shadow: 0 -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  83.33% {
    box-shadow: 2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  91.67% {
    box-shadow: 3px -2px 0 6px rgba(0, 0, 0, 0.5)
  }
  100% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
}

@-webkit-keyframes ld-shadow-a {
  0% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  8.33% {
    box-shadow: 3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  16.67% {
    box-shadow: 2px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  25% {
    box-shadow: 0 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  33.33% {
    box-shadow: -1px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  41.67% {
    box-shadow: -3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  50% {
    box-shadow: -3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  58.33% {
    box-shadow: -3px -1px 0 6px rgba(0, 0, 0, 0.5)
  }
  66.67% {
    box-shadow: -2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  75% {
    box-shadow: 0 -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  83.33% {
    box-shadow: 2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  91.67% {
    box-shadow: 3px -2px 0 6px rgba(0, 0, 0, 0.5)
  }
  100% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
}

@-o-keyframes ld-shadow-a {
  0 {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  8.33% {
    box-shadow: 3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  16.67% {
    box-shadow: 2px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  25% {
    box-shadow: 0 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  33.33% {
    box-shadow: -1px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  41.67% {
    box-shadow: -3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  50% {
    box-shadow: -3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  58.33% {
    box-shadow: -3px -1px 0 6px rgba(0, 0, 0, 0.5)
  }
  66.67% {
    box-shadow: -2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  75% {
    box-shadow: 0 -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  83.33% {
    box-shadow: 2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  91.67% {
    box-shadow: 3px -2px 0 6px rgba(0, 0, 0, 0.5)
  }
  100% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
}

@keyframes ld-shadow-a {
  0% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  8.33% {
    box-shadow: 3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  16.67% {
    box-shadow: 2px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  25% {
    box-shadow: 0 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  33.33% {
    box-shadow: -1px 3px 0 6px rgba(0, 0, 0, 0.5)
  }
  41.67% {
    box-shadow: -3px 1px 0 6px rgba(0, 0, 0, 0.5)
  }
  50% {
    box-shadow: -3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
  58.33% {
    box-shadow: -3px -1px 0 6px rgba(0, 0, 0, 0.5)
  }
  66.67% {
    box-shadow: -2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  75% {
    box-shadow: 0 -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  83.33% {
    box-shadow: 2px -3px 0 6px rgba(0, 0, 0, 0.5)
  }
  91.67% {
    box-shadow: 3px -2px 0 6px rgba(0, 0, 0, 0.5)
  }
  100% {
    box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5)
  }
}

.ld.ld-shadow-a {
  -webkit-animation: ld-shadow-a .5s infinite;
  animation: ld-shadow-a .5s infinite;
  box-shadow: 3px 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 50%
}

.ld.reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse
}

.ld.xhalf {
  -webkit-animation-duration: .5s;
  animation-duration: .5s
}

.ld.x1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s
}

.ld.x2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s
}

.ld.x4 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s
}

.ld.running {
  -webkit-animation-play-state: running;
  animation-play-state: running
}

.ld.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused
}

.ld.f00 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s
}

.ld.f01 {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s
}

.ld.f02 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s
}

.ld.f03 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s
}

.ld.f04 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s
}

.ld.f05 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s
}

.ld.f06 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s
}

.ld.f07 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s
}

.ld.f08 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s
}

.ld.f09 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s
}

.ld.f10 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s
}

.ld-ball, .ld-ring, .ld-hourglass, .ld-loader, .ld-cross, .ld-square, .ld-pie, .ld-spinner {
  width: 1em;
  height: 1em;
  position: relative;
  color: inherit
}

.ld-ball:after, .ld-ring:after, .ld-hourglass:after, .ld-loader:after, .ld-cross:after, .ld-square:after, .ld-pie:after, .ld-spinner:after {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: inline-block;
  background: center center no-repeat;
  background-size: cover
}

.ld-ball:after {
  border-radius: 50%;
  background: currentColor
}

.ld-pie:after {
  width: 0;
  height: 0;
  border-radius: 50%;
  border: .5em solid;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent
}

.ld-ring:after {
  border-radius: 50%;
  border: .2em solid;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent
}

.ld-hourglass:after {
  width: 0;
  height: 0;
  background: 0;
  border-radius: 50%;
  border: .5em solid;
  border-color: currentColor transparent currentColor transparent
}

.ld-cross:after {
  width: 18%;
  height: 18%;
  background: currentColor;
  box-shadow: 0 .18em 0 1px currentColor, 0 -0.18em 0 1px currentColor, 0.18em 0 0 1px currentColor, -0.18em 0 0 1px currentColor, 0 .36em 0 1px currentColor, 0 -0.36em 0 1px currentColor, 0.36em 0 0 1px currentColor, -0.36em 0 0 1px currentColor
}

.ld-square:after {
  width: 90%;
  height: 90%;
  background: currentColor
}

.ld-spinner:after {
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background: 0;
  box-shadow: 0 .5em 0 0 currentColor, 0 -.5em 0 0 currentColor, .5em 0 0 0 currentColor, -.5em 0 0 0 currentColor, .35355339059327373em .35355339059327373em 0 0 currentColor, -.35355339059327373em .35355339059327373em 0 0 currentColor, .35355339059327373em -.35355339059327373em 0 0 currentColor, -.35355339059327373em -.35355339059327373em 0 0 currentColor
}

.ld-loader {
  background-size: cover
}
/* stylelint-enable */