.cashier-report-report {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  min-height: 0;
}

.cashier-report-report__header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid #d9d9d9;
}

.report-header__top-row {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  margin-right: 216px;
}

.report-header__title {
  font-size: 24px;
  text-align: center;
  flex-grow: 1;
}

.report-header__date {
  font-weight: bold;
}

.report-header__total-sum {
  font-weight: bold;
}

.cashier-report-report__tabs {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.pricetype__tab {
  height: 48px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  border: 1px solid #00AA46;
  font-weight: bold;
  font-size: 18px;
  margin: 12px;
  margin-top: 0px;
}

.pricetype__tab:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pricetype__tab:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pricetype__tab_selected {
  background-color: #00AA46;
  color: white;
}

.pricetype__tab_left {
  margin-right: 0;
}

.pricetype__tab_right {
  margin-left: 0;
}

.cashier-report-report__modified {
  color: #323232;
  font-weight: bold;
  background-color: #d9d9d9;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
}

.cashier-report-report__content-container {
  overflow: scroll;
  width: 100%;
  flex-grow: 1;
}

.cashier-report-report__content {
  padding: 36px 0px;
  flex-grow: 1;
}

.cashier-report-report__categories {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}

.report_category__subheader {
  font-weight: bold;
  font-size: 24px;
  margin: 24px;
  margin-left: 0px;
}

.report_category__items {
  width: 744px;
  flex-direction: column;
  justify-content: flex-start;
}

.report_category__item {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-top: 0;
  align-items: center;
  height: 96px;
}

.report_category__item_selected {
  background-color: #f7fcf9;
}

.report_category__item:first-child {
  border-top: 1px solid #d9d9d9;
}

.category_item__cell_checked {
  margin: 12px;
}

.category_item__cell_count {
  flex: 0 1 auto;
  font-weight: bold;
  font-size: 36px;
  margin: 12px;
}

.category_item__cell_name {
  display: flex;
  flex: 3;
  justify-content: flex-start;
  font-size: 24px;
  font-weight: bold;
  margin: 12px;
}

.category_item__cell_sum {
  text-align: end;
  font-size: 24px;
  margin: 12px;
  flex: 1;
}
.category_item__cell_sum__original {
  font-size: 18px;
  text-decoration: line-through;
}
.category_item__cell_sum__discounted {
  font-weight: bold;
}
.category_item__cell_discount {
  display: flex;
  align-items: center;
  height: 36px;
  width: 72px;
  background-color: #FFAA00;
  color: white;
  font-size: 24px;
  padding: 4px 6px;
  font-weight: bold;
  border-radius: 2px;
  text-align: left;
  box-sizing: border-box;
}
.report__footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: #f7fcf9;
  border-top: 1px solid #00AA46;
  flex-shrink: 0;
}

.footer__button {
  height: 60px;
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 12px 6px;
  border-radius: 2px;
  border: 1px solid #00AA46;
  font-weight: bold;
  font-size: 18px;
}

.footer__button_enabled {
  background-color: #00AA46;
  color: white;
}

.footer__button_disabled {
  background-color: #fbfbfb;
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
  border-radius: 2px;
}

.footer__sum {
  display: flex;
  flex-direction: column;
  width: 33%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.cashier-report-report__confirm {
  justify-content: center;
}

.report-confirm__title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.report-confirm__sum {
  font-size: 120px;
  font-weight: bold;
  text-align: center;
  margin: 54px 0px;
}

.report-confirm__buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.report-confirm__button {
  height: 60px;
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 12px 6px;
  border-radius: 2px;
  border: 1px solid;
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.report-confirm__button_positive {
  background-color: #00AA46;
  border-color: #00AA46;
}

.report-confirm__button_negative {
  background-color: #ff9100;
  border-color: #ff9100;
}