.full-screen-notification {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.full-screen-notification_urgency-1 {
  background-color: #00aa46;
  color: white;
}

.full-screen-notification_urgency-2 {
  background-color: #ffaa00;
  color: white;
}

.full-screen-notification_urgency-3 {
  background-color: #ff2c2c;
  color: white;
}

.full-screen-notification__description {
  font-size: 96px;
  font-weight: bold;
}

.full-screen-notification__title {
  font-size: 224px;
  margin: 24px 0px 29px 0px;
  font-weight: bold;
}
.full-screen-notification__text--font-medium {
  font-size: 72px;
}
.full-screen-notification__text--font-small {
  font-size: 36px;
}
.full-screen-notification__close-button {
  height: 48px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 12px 6px;
  border-radius: 2px;
  border: 2px solid;
  font-weight: bold;
  font-size: 18px;
}

.full-screen-notification__close-button_non-urgent {
  background-color: #00aa46;
  color: white;
}
.full-screen-outer-container {
  display: flex;
  width: 100%;
  height: 100%;
}