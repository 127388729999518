@font-face {
  font-family: "Roboto";
  src: url(../assets/fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url(../assets/fonts/Roboto-Regular.ttf) format("truetype");
}

body {
  font-family: "Roboto";
  font-size: 18px;
  color: #323232;
}

html, body
{
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.flexbox-parent
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: flex-start; /* align items in Main Axis */
    align-items: stretch; /* align items in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */

    background: rgba(255, 255, 255, .1);
}

.flexbox-item-grow
{
    flex: 1; /* same as flex: 1 1 auto; */
}

.fill-area
{
    display: flex;
    flex-direction: row;

    justify-content: flex-start; /* align items in Main Axis */
    align-items: stretch; /* align items in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */

    min-height: 0;
}

.side-bar {
  flex: 1;
  display: flex;
  border-right: 1px solid #d9d9d9;
  min-height: 0;
}

.content {
  flex: 4;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.icon-takeaway {
  background-image: url(../assets/icons/takeaway.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 25px;
}

.icon-pizza {
  background-image: url(../assets/icons/pizza.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 20px;
}

.icon-hamburger {
  background-image: url(../assets/icons/hamburger.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 20px;
}

.icon-settings {
  background-image: url(../assets/icons/settings.svg);
  background-repeat: no-repeat;
  width: 30px;
  height: 6px;
}

.icon-message {
  background-image: url(../assets/icons/message.svg);
  background-repeat: no-repeat;
  width: 21px;
  height: 20px;
}

.checkbox__checkmark {
  background-image: url(../assets/icons/check.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.icon-backspace {
  background-image: url(../assets/icons/backspace.svg);
  background-repeat: no-repeat;
  width: 40px;
  height: 28px;
}

.cashier-report-list__title,
.queue-options__title,
.purchased-buffets__container-title,
.market-orders__container-title,
.order-settings__title {
  font-size: 36px;
  font-weight: bold;
  margin: 24px 0px 24px 0px;
}

.purchased-buffets__container,
.market-orders__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.queue-options,
.view-options,
.order-settings {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.view-options__title {
  font-size: 36px;
  font-weight: bold;
  margin: 24px 0px 12px 0px;
}

.view-options__buttons {
  display: flex;
  flex-direction: row;
  margin: 12px 0px;
}

.queue-options__buttons {
  display: flex;
  flex-direction: row;
}

.view-options__button {
  height: 48px;
  width: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 12px 6px;
  border-radius: 2px;
  border: 1px solid #00AA46;
  font-weight: bold;
  font-size: 24px;
}

.queue-options__button {
  height: 48px;
  width: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 6px;
  border-radius: 2px;
  border: 1px solid #00AA46;
  font-weight: bold;
  font-size: 24px;
}

.view-options__button-selected,
.queue-options__button-selected {
  background-color: #00AA46;
  color: white;
  border: none;
  width: 216px;
  height: 50px;
}

.view-actions {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.view-actions__title {
  font-size: 36px;
  font-weight: bold;
  margin: 24px 0px 12px 0px;
}

.view-actions__button {
  background-color: #00AA46;
  color: white;
  width: 346px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 12px 6px;
  border-radius: 2px;
  border: 1px solid #00AA46;
  font-weight: bold;
  font-size: 24px;
}

.order-id-badge {
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #323232;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 2px;
  border-radius: 2px;
  padding: 0px 10px;
  width: 86px;
}

.details-order-id-badge {
  padding: 0px 24px;
}

.order-settings-button {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin-left: 24px;
  width: 60px;
  height: 60px;
  border: 2px solid #00AA46;
}

.button {
  min-height: 48px;
  height: 48px;
  width: 216px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  padding: 12px;
  margin: 12px;
  border-radius: 2px;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.button-positive {
  background-color: #00AA46;
}

.button-warn {
  background-color: #FFAA00
}

.error-toast-container {
  margin: 10px 0 0 0;
  padding: 0;
  width: 840px;
  height: 64px;
  margin-left: -240px;
  border-radius: 2px;
}

.error-toast-container-body {
  margin: 0;
  padding: 0;
}

.error-toast {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid red;
  border-radius: 2px;
}

.error-toast-content {
  flex: 6;
  color: rgba(255, 44, 44, 1);
  background-color: rgba(255, 244, 244, 1);
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 32px;
  border-radius: 2px;
  padding: 15px 12px 15px 12px;
  font-size: 15pt;
  font-weight: bold;
}

.error-toast-close-button {
  flex: 1;
  border: 0;
  color: white;
  background-color: rgba(255, 44, 44, 1);
}
