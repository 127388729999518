.notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: stretch; /* Extra space in Cross Axis */
}

.notification__title {
  font-size: 36px;
  font-weight: bold;
  margin: 24px 0px 24px 0px;
}

.notification__description {
  margin: 12px 24px 36px 24px;
}

.notification__action {
  color: #FFFFFF;
  background-color: #00AA46;
  font-size: 24px;
  font-weight: bold;
  height: 60px;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 2px;
  min-width: 240px;
}