.cashier-report-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
}

.cashier-report-list__content {
}

.monthly-report-list {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.monthly-report-list__subheader {
  font-weight: bold;
  font-size: 24px;
  margin: 24px;
}

.monthly-report-list__rows {
  width: 744px;
  flex-direction: column;
  justify-content: flex-start;
}

.report-list__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-top: 0;
  align-items: center;
  height: 96px;
}

.report-list__row:first-child {
  border-top: 1px solid #d9d9d9;
}

.report-list__row_unfinished {
  background-color: #fff9f2;
}

.report-list__row_done {
  background-color: #f7fcf9;
}

.report-list-row__checkbox {
  margin: 12px;
}

.report-list-row__date {
  flex: 0 1 auto;
  font-weight: bold;
  font-size: 24px;
  margin: 12px;
}

.report-list-row__completed {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 12px;
}

.report-list-row__status {
  text-align: end;
  width: 100px;
  font-weight: bold;
  font-size: 24px;
  margin: 12px;
}

.report-list-row__arrow-right {
  background-image: url(../../../assets/icons/arrow-right.svg);
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin: 12px;
  margin-left: 0;
}

.report-list__row_unfinished .checkbox {
  border-color: #ff9100;
  background-color: #ff9100;
}